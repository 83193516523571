import React from "react";
import { ReactComponent as IconFacebook } from "./assets/icons/facebook.svg";
import { ReactComponent as IconTwitter } from "./assets/icons/twitter.svg";
import { ReactComponent as IconGithub } from "./assets/icons/github.svg";
import { ReactComponent as IconInstagram } from "./assets/icons/instagram.svg";
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href="."> </a>
          </div>
          <div className="social">
            <a
              href="https://twitter.com/griffingamesinc"
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: 24 }}
            >
              <IconTwitter className="icon" />
            </a>
            <a
              href="https://www.instagram.com/griffingamesinc/"
              title="GitHub"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconInstagram className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Under Construction</h1>
            <p>Pardon our dust!</p>
          </div>
        </div>
      </div>
    );
  };
}

export default App;
